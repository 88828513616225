import { CustomErrorStringMap, KeyValueMap } from "../types/interfaces";

export const colorList = [
  "#2d5c7c",
  "#5d8199",
  "#92bccc",
  "#237777",
  "#376c6c",
  "#63a5a5",
  "#9d5961",
  "#815a5f",
  "#9e7b80",
  "#373b42",
  "#4b5b64",
  "#77848c",
  "#9c8c6f",
  "#6f695d",
  "#989a94",
];

export const tableIds: KeyValueMap = {
  ADD: "add",
  ARROWS: "arrows",
  BARCODE: "barcode",
  BINKEY: "binkey",
  BOX: "box",
  BRANCHID: "branchid",
  BRANCHNAME: "branchname",
  COUNTRY: "country",
  BRANCHES: "branches",
  CREATORS: "creators",
  DATE: "date",
  DAY: "day",
  DELETE: "delete",
  DESCRIPTION: "desc",
  EDIT: "edit",
  EDITOR: "editor",
  EMAIL: "email",
  FILENAME: "filename",
  GATEID: "gateid",
  GATELOCATION: "gatelocation",
  HOUR: "hour",
  ICON: "icon",
  ID: "id",
  IN: "in",
  ISO: "iso",
  KEY: "key",
  LANGUAGE: "language",
  NAME: "name",
  ORDER: "order",
  OUT: "out",
  PARTNERID: "partnerid",
  PATRONCARDNUMBER: "patroncardnumber",
  PLAYER: "player",
  PRODUCTS: "products",
  ROLES: "roles",
  RPORTID: "rportid",
  SHOWN_FROM: "shownfrom",
  SHOWN_TO: "shownto",
  SIZE: "size",
  STATUS: "status",
  TIMESTAMP: "timestamp",
  TITLE: "title",
  TRANSLATION: "translation",
  UNITTYPE: "unittype",
  USER: "user",
  VALUE: "value",
  VERIFIED: "verified",
};

export const environments = {
  LOCAL: "development",
  DEV: "dev",
  STAGING: "staging",
  PROD: "production",
};

export const CICERO: string = "cicero";
export const CICERO_DEICHMAN: string = "cicero_deichman";
export const BOOKIT: string = "bookit";
export const BIBLIOFIL: string = "bibliofil";
export const FBS: string = "fbs";
export const QURIA: string = "quria";
export const DEICHMAN: string = "deichman";
export const MIKROMARC: string = "mikromarc";
export const OPEN_LIBRARY_API: string = "open_library_api";

export const customErrorStringMap: CustomErrorStringMap = {
  SORT_BINS_FAILED: "sortBinsFailed",
  BIN_KEY_FAILED: "binkeyFailed",
  FAILED: "failed",
};

export const customId = "custom";
export const showAllId = "all";
export const accessDenied = "access_denied";
export const patronBlocked = "patron_blocked";
export const userIsInvalid = "user_is_invalid";
export const userAccessDenied = "user_access_denied";
export const userAccessGranted = "user_access_granted";
export const userIsAgeRestricted = "user_is_age_restricted";
export const wrongUsernameOrPassword = "wrong_username_or_password";

export const allCustomersId = "allCustomers";
export const customerNotloaded = "notLoaded";

export const MaxMajorValue: number = 65535;
export const MaxMinorValue: number = 65535;

export const i18nLanguageKeyMap: KeyValueMap = {
  da: "dan",
  no: "nno",
  nb: "nob",
  sw: "swe",
  en: "eng",
};

export const acceptNone = "acceptNone";
export const acceptAll = "acceptAll";

export const urlProtocolShort = "http:";
export const urlProtocol = "http://";
export const urlProtocolSecure = "https://";

export interface DateInterval {
  start: string;
  end: string;
}

export type UnitsOfTime = "day" | "week" | "month" | "year";
export type DateCompareType = "previous_year" | "previous_period";

export const unitsOfTime: Record<UnitsOfTime, UnitsOfTime> = {
  day: "day",
  week: "week",
  month: "month",
  year: "year",
};

export const dateCompareType: Record<DateCompareType, DateCompareType> = {
  previous_year: "previous_year",
  previous_period: "previous_period",
};

export const systemEmailDomain = "@redia.dk";
